import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { Constant } from 'src/app/constants/constant';
import { MenuItems } from 'src/app/Core/menu/menu-items/menu-items';
import { SupplierDialogComponent } from 'src/app/pages/supplier-registration/supplier-dialog/supplier-dialog.component';
import { ConfigService } from 'src/app/services/config.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'calsoft-footer2',
  templateUrl: './footer2.component.html',
  styleUrls: ['./footer2.component.scss']
})
export class Footer2Component implements OnInit {
  public lat: number = 40.678178;
  public lng: number = -73.944158;
  public zoom: number = 12;
  public siteName = Constant.siteName
  @Input() data: any;
  menuData: any=[];
  display: boolean=false;
  footerLogoSrc: any;
  public serverPath = environment.commonImageApi + "footerImage/";
  footerImageEnable: any=0;
  dspeedupNavigationEnable:any=0;
  
  constructor(    public menuItems: MenuItems,
    private configService:ConfigService,
    private domSanitizer: DomSanitizer,
    private matIconRegistry: MatIconRegistry,
    private dialog: MatDialog,
    public router: Router,
    ) {

      this.matIconRegistry.addSvgIcon(
        "Facebook",
        this.domSanitizer.bypassSecurityTrustResourceUrl("assets/icons/facebook.svg")
      );
      this.matIconRegistry.addSvgIcon(
        "Twitter",
        this.domSanitizer.bypassSecurityTrustResourceUrl("assets/icons/twitter.svg")
      );
      this.matIconRegistry.addSvgIcon(
        "Youtube",
        this.domSanitizer.bypassSecurityTrustResourceUrl("assets/icons/youtube.svg")
      );
      this.matIconRegistry.addSvgIcon(
        "Tumblr",
        this.domSanitizer.bypassSecurityTrustResourceUrl("assets/icons/tumblr.svg")
      );

      this.matIconRegistry.addSvgIcon(
        "Linkedin",
        this.domSanitizer.bypassSecurityTrustResourceUrl("assets/icons/linkedin.svg")
      );
      this.matIconRegistry.addSvgIcon(
        "Instagram",
        this.domSanitizer.bypassSecurityTrustResourceUrl("assets/icons/instagram.svg")
      );  
     }

     currentYear: number = new Date().getFullYear();

  ngOnInit() {

    if(window.innerWidth>959){
      this.display=true;
    }

     
    if(this.data.footerLogoSrc!=undefined){
      this.footerLogoSrc=this.data.footerLogoSrc;
    }

    if(this.data.footerImageEnable!=undefined){
      this.footerImageEnable=this.data.footerImageEnable;
    }

    if(this.data.dspeedupNavigationEnable!=undefined){
      this.dspeedupNavigationEnable=this.data.dspeedupNavigationEnable;
    }
    
    this.getFooterMenuData()
    if(this.data.showFooterIcon==1){

      this.showFooterIcon=1;
      this.getFooterIcon();
    }
   }

   callPhone() {
    let phoneNumber = "tel:" + this.data.storePhoneNumber;
    window.location.href = phoneNumber;
  }

  subscribe(){ }



  getFooterMenuData(){

    if((this.data.showContactPage==1&&this.data.showBlogPage==0)){

      this.menuData=this.menuItems.getFooterOneMenu()
    }else if(this.data.showContactPage==0 &&this.data.showBlogPage==0 ){
      this.menuData=this.menuItems.getFooterTwoMenu()
    }else if(this.data.showContactPage==0 &&this.data.showBlogPage==1 ){
      this.menuData=this.menuItems.getFooterThirdMenu()
    }else if(this.data.showContactPage==1 &&this.data.showBlogPage==1 ){
      this.menuData=this.menuItems.getFooterFourthMenu()
    }
  }
  footerIconData: any=[];
  showFooterIcon:any=0;
  getFooterIcon() {
    this.configService.getFooterData()
      .subscribe(
        data => {

        
          if(data['datalist'].length==0){
            this.showFooterIcon=0;
            this.footerIconData=[];
          }else{
            this.footerIconData=data['datalist'];
          }

          if(this.data.footerLogoSrc!=undefined){
            this.footerLogoSrc=this.data.footerLogoSrc;
          }
          
          }, error => {

          }
          );
}

navigate(data:any) {

  if(data.linkNavigated==1){
  
    if(data.link!=null){
      let   url = data.link
      window.location.href=url;
    }
    
  }
}

Supplierlogin() {
  if (window.innerWidth > 768) {

    let dialogRef = this.dialog.open(SupplierDialogComponent, {
    width: '790px',
    });

    dialogRef.disableClose = true;
    dialogRef.afterClosed().subscribe(result => {
    this.ngOnInit();


    });

  }
  else {
    let dialogRef = this.dialog.open(SupplierDialogComponent, {
    minWidth: '100vw', height: '100vh',

    });

    dialogRef.disableClose = true;
    dialogRef.afterClosed().subscribe(result => {
    this.ngOnInit();


    });
  }

  }

  composeEmail(smtpUsername) {
    window.location.href = 'mailto:' + smtpUsername;
  }
  

  getDirections(googleMapNavigation:string): void{
    if (googleMapNavigation && googleMapNavigation.toLowerCase() !== 'none') {
      let url = googleMapNavigation.toLowerCase();
      
      if (!url.startsWith('http://') && !url.startsWith('https://')) {
        url = 'http://' + url;
      }
  
      window.open(url, '_blank');
    }

  }

  gotoLink(url: string) {
    if (url) {
      const fullURL = this.prependProtocol(url);
      window.open(fullURL, '_blank');
    }
  }
  
  prependProtocol(url: string): string {
    if (!url.includes('http://') && !url.includes('https://')) {
      return 'https://' + url;
    }
    return url;
  }

  becomePartner(){
    this.router.navigate(['/partnersLogin']);
  }

}