<div  fxLayout="row" fxLayoutGap="5px" style="background-color: white;height: 50px;" fxLayoutAlign="start center">
  <button  mat-icon-button (click)="goBack()">
      <mat-icon>
          keyboard_backspace
      </mat-icon>
  </button>
  <h5><b>Select Location</b></h5>
</div>
<div class="map-container">
  <div id="map" class="map" ></div>
</div>
<!-- <div fxLayout="row"  fxLayoutGap="5px">
  <div fxLayout="column" fxFlex="100">
    <div fxLayout="row" >
      <div class="map-wrapper"  fxFlex="100">
        <button mat-fab (click)="goBack()" style="background-color: white; color: black;" class="close-button">
          <mat-icon>chevron_left</mat-icon>
        </button>
        <div class="map-container">
          <div id="map" class="map" ></div>
        </div>
    </div> 
    </div> 
  </div>
</div> -->


<div fxLayout="row" style="padding-top: 15px;">
  <div fxFlex="10" fxLayoutAlign="center center">
      <mat-icon mat-list-icon style="font-size: 26px; display: flex; align-items: center;">location_on</mat-icon>
  </div>
  <div fxFlex="60">
      <div style="font-size: 2rem;"><b>{{ title }}</b></div>
  </div>
  <div fxFlex="30" fxLayoutAlign="center center">
      <button mat-button style="border: 1px solid;border-radius: 20px;" (click)="goBack()">CHANGE</button>
  </div>
</div>

<div fxLayout="row">
  <div fxFlex="80">
      <div style="font-size: 15px;font-weight: 500;padding-left: 10px;padding-bottom: 15px;">{{addressDetail}} </div>
  </div>
  </div>
  
<div fxLayout="row" fxLayoutGap="5px" >
  <div fxLayout="column" fxFlex="100">
    <div class="padding-l15 padding-b10 padding-r15" fxFlex fxLayout="column">
      <div fxLayout="row" >
        <button fxLayoutGap="5px" style="color: white;background-color: orange;" (click)="userGetLocation()" fxFlex mat-raised-button class="primary"  >
          Locate Me
        </button>
      </div> 
    </div>
  </div>
</div>



<div fxLayout="row" fxLayoutGap="5px" *ngIf="!routelocation">
  <div fxLayout="column" fxFlex="100">
    <div class="padding-l15 padding-b10 padding-r15" fxFlex fxLayout="column">
      <div fxLayout="row" >
        <button fxLayoutGap="5px" style="color: white;background-color: orange;" (click)="saveAddress()" fxFlex mat-raised-button class="primary"  >
          SAVE & PROCEED
        </button>
      </div> 
    </div>
  </div>
</div>
  
<div fxLayout="row" fxLayoutGap="5px" *ngIf="routelocation">
  <div fxLayout="column" fxFlex="100">
    <div class="padding-l15 padding-b10 padding-r15" fxFlex fxLayout="column">
      <div fxLayout="row" >
        <button fxLayoutGap="5px" style="color: white;" (click)="saveAddress()" fxFlex mat-raised-button class="primary"  >
        {{buttonTitle}}
        </button>    
      </div> 
    </div>
  </div>
</div>


