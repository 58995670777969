import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class RecentSearchService {

  private storageKey = 'recentSearches';
  private maxRecentSearches = 5; // Limit the number of recent searches stored

  constructor() { }

  getRecentSearches(): any[] {
    const searches = localStorage.getItem(this.storageKey);
    return searches ? JSON.parse(searches) : [];
  }

  addSearch(term: any) {
    let searches = this.getRecentSearches();
    
    // Remove the term if it already exists
    searches = searches.filter(search => search.title !== term.title && 
      search.latitude !== term.latitude && search.longitude !== term.longitude
    );

    // Add the term to the top of the list
    searches.unshift(term);

    // Ensure the list doesn't exceed the maximum number of recent searches
    if (searches.length > this.maxRecentSearches) {
      searches = searches.slice(0, this.maxRecentSearches);
    }

    localStorage.setItem(this.storageKey, JSON.stringify(searches));
  }


}
