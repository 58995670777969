import { Component, ElementRef, EventEmitter, Input, OnInit, Output, Renderer2, ViewChild } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { CommonService } from 'src/app/services/common.service';
import { GoogleMapService } from 'src/app/services/google-map.service';
import { LocationService } from 'src/app/services/location.service';
import { ProductService } from 'src/app/services/product.service';
import { Location } from '@angular/common';
import { LoaderService } from "src/app/services/loader.service";
import { RecentSearchService } from 'src/app/services/recent-search.service';

declare var google:any;

@Component({
  selector: 'calsoft-map-location',
  templateUrl: './map-location.component.html',
  styleUrls: ['./map-location.component.scss']
})
export class MapLocationComponent implements OnInit {
    map: any;
  	latitude: any
  	longitude: any;
    marker:any;
    private googleMapsScriptLoaded: boolean = false;

    @Input() address: any;
    @Input() center = { lat: 28.649944693035188, lng: 77.23961776224988 };
    @Input() update = false;
    @Input() commonData: any;
    @Input() title: any;
    @Input() type: any;
  
    @Input() buttonTitle: any;
    mapListener: any;
    mapChange: Subscription;
    data: any;
    addressDetail: any;
    savedAddress: any;
    @Output() gpsAddressData: EventEmitter<any> = new EventEmitter<any>();
    @Output() goBackToLocation: EventEmitter<any> = new EventEmitter<any>();
    locationSelected: boolean;
    location: any = {};
    isLocationFetched: boolean;
    routelocation:boolean;
    separatedAddress: any;
    googleApiKey:any;

  constructor(
    public _location: Location,
    public productService: ProductService,
    private commonService: CommonService,
    public formBuilder: FormBuilder,
    private maps: GoogleMapService,
    private locationService: LocationService,
    public router: Router,
    private dialog: MatDialog,
    private renderer: Renderer2,
    private loaderService: LoaderService,
    private recentSearchService: RecentSearchService
  ) {
    this.location.title = 'Locating...';
    this.isLocationFetched = false;

  }

  ngOnInit() {

    this.googleApiKey = this.commonData['fullDat']['googleApi'];

    this.latitude = localStorage.getItem('latitude');
    this.longitude = localStorage.getItem('longitude');

      if (this.type == 1) {
        this.title = this.title;
      }

      this.loadGoogleMapsScript();
      const currentPage = this.router.url;
      if(this.router.url == '/home'){
          this.routelocation = true;
      }
      else if(this.router.url !== '/home'){
        this.routelocation = false;
      }


  }

  loadGoogleMapsScript() {
    if (!this.googleMapsScriptLoaded) {
      const script = document.createElement('script');
      script.src = 'https://maps.googleapis.com/maps/api/js?key='+this.googleApiKey+'&libraries=places';
      script.async = true;
      script.defer = true;
      script.onload = () => {
        this.googleMapsScriptLoaded = true;
        if (this.type == 1) {
          this.displayPlaceMap();
        }
        else if(localStorage.getItem('latitude') && localStorage.getItem('longitude')){
          this.displayPlaceMap();
        } 
        else{
          this.displayMap();
        }
      };
      document.body.appendChild(script);
    } else {
      this.displayMap();
    }
  }

  displayPlaceMap() {
    let style = [
      {
        featureType: 'all',
        elementType: 'all',
        stylers: [
          { saturation: -100 }
        ]
      }
    ];

    let latLng = new google.maps.LatLng(this.latitude, this.longitude);
    let mapOptions = {
      center: latLng,
      zoom: 18,
      scaleControl: false,
      streetViewControl: false,
      zoomControl: false,
      overviewMapControl: false,
      mapTypeControl: false,
      mapTypeId: 'SwiggyClone', // Use the identifier as a string, not a constructor
      mapTypeControlOptions: {
       mapTypeIds: [google.maps.MapTypeId.ROADMAP, 'SwiggyClone']
       }
    }

      this.map = new google.maps.Map(document.getElementById('map'), mapOptions);
      var mapType = new google.maps.StyledMapType(style, { name: 'Grayscale' });
      this.map.mapTypes.set('SwiggyClone', mapType);
      this.map.setMapTypeId('SwiggyClone');
      this.addMarker(this.latitude, this.longitude);
      this.reverseGeocode(this.latitude, this.longitude, this.googleApiKey);    
  }

  displayMap() {

    let options: any = {
      maximumAge: 0,
      timeout: 5000,
      enableHeightAccuracy: false
    };
    
    let style = [
      {
        featureType: 'all',
        elementType: 'all',
        stylers: [
          { saturation: -100 }
        ]
      }
    ];


    navigator.geolocation.getCurrentPosition((resp) => {
     // console.log(resp);
      this.latitude = resp.coords.latitude;
      this.longitude = resp.coords.longitude;
     
   let latLng = new google.maps.LatLng(resp.coords.latitude, resp.coords.longitude);
      let mapOptions = {
        center: latLng,
        zoom: 18,
        scaleControl: false,
        streetViewControl: false,
        zoomControl: false,
        overviewMapControl: false,
        mapTypeControl: false,
        mapTypeId: 'SwiggyClone', // Use the identifier as a string, not a constructor
        mapTypeControlOptions: {
         mapTypeIds: [google.maps.MapTypeId.ROADMAP, 'SwiggyClone']
     }
    };

      this.map = new google.maps.Map(document.getElementById('map'), mapOptions);
        // Create a custom map type
        var mapType = new google.maps.StyledMapType(style, { name: 'Grayscale' });
        this.map.mapTypes.set('SwiggyClone', mapType);

        // Set the map to use the custom map type
        this.map.setMapTypeId('SwiggyClone');


      this.addMarker(resp.coords.latitude, resp.coords.longitude);
      this.reverseGeocode(resp.coords.latitude, resp.coords.longitude, this.googleApiKey);    
    
    },
    (error) => {
      if (error.code === error.PERMISSION_DENIED) {
        //alert('permission denied');
        this.router.navigate(['/location-error']);
      } else {
        console.error('Geolocation error:', error);
      }
    },
    options
  );

}

addMarker(latitude, longitude) {

  const icon = {
    url: 'assets/icons/location-pin.png',
    scaledSize: new google.maps.Size(50, 50),
  };


  this.marker = new google.maps.Marker({
    position: new google.maps.LatLng(latitude, longitude),
    animation: google.maps.Animation.DROP,
    map: this.map,
    icon: icon,
    draggable: true,
    });

    this.marker.addListener('dragend', () => {
      console.log('markerchange');
      //console.log('Marker position:', this.marker.getPosition().toJSON());
      this.latitude = this.marker.getPosition().toJSON().lat;
      this.longitude = this.marker.getPosition().toJSON().lng;
      this.type=0;
      localStorage.setItem('latitude',  this.latitude);
      localStorage.setItem('longitude',  this.longitude);
      this.reverseGeocode(this.latitude, this.longitude, this.googleApiKey);    
    });

}

reverseGeocode(latitude:any, longitude:any, apiKey:any) {
  console.log(latitude);
  console.log(longitude);  

  this.loaderService.show();
  const apiUrl = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=${apiKey}`;
  
  fetch(apiUrl)
      .then(response => response.json())
      .then(data => {
          if (data.status === "OK") {
            this.loaderService.hide();
              const map_Address = data.results[0];
              this.addressDetail = map_Address.formatted_address;
              const addressComponents = map_Address.address_components;
              
              let previousValue = '';
              this.separatedAddress = {
                address: '',
                city: '',
                district: '',
                state: '',
                country: '',
                pincode: '',
                full_address: this.addressDetail,
                longitude: map_Address.geometry.location.lng,
                latitude: map_Address.geometry.location.lat
              };
        
              let value=false;
              addressComponents.forEach((component: any) => {
                const types = component.types;
                if (types.includes('street_number') || types.includes('route') || types.includes('locality')
                  || types.includes('neighborhood') || types.includes('sublocality')) {
                  this.separatedAddress.address += component.long_name + ' ';
                  if(this.type==0&&!value){
                    this.title = component.long_name;
                    value=true;
                  }
        
                } else if (types.includes('locality')) {
                  this.separatedAddress.city = component.long_name;
                } else if (types.includes('administrative_area_level_3')) {
                  this.separatedAddress.district = component.long_name;
                } else if (types.includes('administrative_area_level_1')) {
                  this.separatedAddress.state = component.long_name;
                } else if (types.includes('country')) {
                  this.separatedAddress.country = component.long_name;
                } else if (types.includes('postal_code')) {
                  this.separatedAddress.pincode = component.long_name;
                }
                previousValue = types;
              });
              
                this.latitude = latitude;
                this.longitude = longitude;
                localStorage.setItem('title', this.title);
                
                const location = new google.maps.LatLng(latitude, longitude);
                this.map.panTo(location);
                this.marker.setMap(null);
                this.addMarker(latitude, longitude);
                this.recentSearchService.addSearch({'title':this.title, 'FullAddress':this.separatedAddress.full_address, "latitude":latitude, "longitude":longitude});
          } else {
            this.loaderService.hide();
              console.log("Geocoder failed due to: " + data.status);
          }
      })
      .catch(error => {
        this.loaderService.hide();
        console.log("Unable to get address, failed due to: " + error.message);
      });
}

saveAddress() {   
  this.gpsAddressData.emit(this.separatedAddress);
}

userGetLocation() {
  let options: any = {
    maximumAge: 0,
    timeout: 5000,
    enableHeightAccuracy: false
  };

  navigator.geolocation.getCurrentPosition((position:any) => {

    localStorage.setItem('latitude', position.coords.latitude);
    localStorage.setItem('longitude', position.coords.longitude);

    this.type=0;
    this.locationSelected = true;
    this.reverseGeocode(position.coords.latitude, position.coords.longitude, this.googleApiKey);        
  },
  (error) => {
    if (error.code === error.PERMISSION_DENIED) {
      //alert('permission denied');
      this.router.navigate(['/location-error']);
    } else {
      console.error('Geolocation error:', error);
    }

  },
  options
)
}


goBack() {
  this.goBackToLocation.emit(false)
}


}
