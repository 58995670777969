<div *ngIf="!locationSelected" style="height: 100%;">
    <div [formGroup]="firstFormGroup" fxLayout="row" style="height: 65px;"   class="search backg" fxLayoutAlign="space-between center">
        <div  fxLayout="row" fxLayoutGap="5px">
            <button  mat-icon-button (click)="closeModal()">
                <mat-icon>
                    keyboard_backspace
                </mat-icon>
            </button>
    
            <mat-form-field floatLabel='never'>
                <input matInput value="search " placeholder="Enter area street name ...." formControlName="search"
                (input)="onSearchChange($event)" (keyup.enter)="onKeydown($event)" (click)="onSearch()">
            </mat-form-field>
    
        </div>
        
        <button *ngIf="firstFormGroup.value.search==''"  mat-icon-button (click)="backClicked()">
            <mat-icon>
                search
            </mat-icon>
        </button>
        <button  *ngIf="firstFormGroup.value.search!=''" mat-icon-button (click)="clearSearch()">
            <mat-icon>
                close
            </mat-icon>
        </button>
    </div>
    
    <ng-container *ngIf="firstFormGroup.value.search!=''">
        <div class="backg borderb padding-10"  fxLayoutAlign="start center" *ngFor="let place of places"
        (click)="userPickLocation(place)" >
          <i class="material-icons" >
           location_on
       </i>
           <span> {{place.address}}</span>      
       </div>
    </ng-container>
    
    <div  class="backg borderb padding-10" fxLayoutAlign="start center" fxLayoutGap="10px"  
    (click)="userGetLocation()">
       <i class="material-icons">
        near_me
    </i>
    <span>Use my current location</span>
    </div>
    
    <div *ngIf="showrecent && !locationSelected" style="background-color: white;padding-bottom: 5px;">
        <h6 class="tex1"><b>RECENT SEARCHES</b></h6>
        
        <div fxLayout="row" *ngFor="let search of recentSearches" >
            <div fxFlex="12" fxLayoutAlign="center center">
                <mat-icon style="font-size: 22px;">query_builder</mat-icon>
            </div>
            <div fxFlex="88" style="padding-bottom: 10px;" >
                <p class="searchttext"><b>{{search.title}}</b></p>
                <p class="truncate-text">{{search.FullAddress}}</p>
            </div>
        </div>
    </div>
    

</div>

<div *ngIf="locationSelected" style="height: 100%;">

    <calsoft-map-location fxFlex="100" [commonData]="commonData"
     [buttonTitle]="buttonTitle"
    (goBackToLocation)="setLocationSelected($event)"
        (gpsAddressData)="addressdata($event)" 
        [address]="separatedAddress"
         [type]="type"
        [title]="title"></calsoft-map-location>

</div>

    